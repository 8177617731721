import React from 'react'
import styled from 'styled-components';
import { graphql } from 'gatsby';
import ProductContentImages from './ProductContentImages';
import { cubicBezier, MQ } from '../../assets/styles/constants'

class ProductContent extends React.Component {
  constructor(props) {
    super(props)

    this.flickityRef = React.createRef();
    this.expandedContentRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { expandedText, isActive } = this.props

    // Add event listener when the section is active remove again when it's not
    if (expandedText) {
      if (prevProps.isActive !== false && isActive) {
        document.addEventListener('keydown', this.handleEsc, true);
      } else if (prevProps.isActive && !isActive) {
        document.removeEventListener('keydown', this.handleEsc, true);
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleEsc, true);
  }

  handleEsc = ({ key }) => {
    if (this.props.showModal && key === 'Escape') {
      this.toggleModal();
    }
  }

  toggleModal = open => {
    if (open && this.expandedContentRef && this.expandedContentRef.current) {
      this.expandedContentRef.current.focus();
    }

    this.props.toggleModal(open);
  }

  render() {
    const {
      headline,
      text,
      expandedText,
      images,
      id,
      type,
      showModal,
      imagesFullHeight,
    } = this.props

    const hasImages = images !== null && images.length > 0;

    return (
      <ProductContentWrapper>
        <ProductContentInner type={type}>
          <ProductContentImages images={images} imagesFullHeight={imagesFullHeight} id={id} />
          <Content hasImages={hasImages}>
            <ContentInner hasImages={hasImages}>
              <Headline hasImages={hasImages} dangerouslySetInnerHTML={{ __html: headline }} />
              <ContentText
                dangerouslySetInnerHTML={{ __html: text }}
                hasImages={hasImages}
              />
              {expandedText && (
                <ExpandedTextTrigger showModal={showModal} onClick={() => this.toggleModal(true)} />
              )}
            </ContentInner>
          </Content>
        </ProductContentInner>
        {expandedText && (
          <ExpandedContentWrapper ref={this.expandedContentRef} className="overlay" showModal={showModal} tabIndex="0">
            <ExpandedContentText dangerouslySetInnerHTML={{ __html: expandedText }} />
            <ExpandedContentCloseButton onClick={() => this.toggleModal(false)} />
          </ExpandedContentWrapper>
        )}
      </ProductContentWrapper>
    )
  }
}

export default ProductContent

const ProductContentWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  position: relative;
`

const ProductContentInner = styled.div`
  display: flex;
  flex-direction: ${props => (props.type === 'Text right, image left' ? 'row' : 'row-reverse')};
`

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: ${props => (props.hasImages ? '50vw' : '100vw')};
  height: 100vh;
`

const ContentInner = styled.div`
  text-align: ${props => (props.hasImages ? 'left' : 'center')};
  font-size: ${props => (props.hasImages ? '1.2rem' : '1.4rem')};
  max-width: 85rem;
  padding: 0 7rem 0 4rem;

  @media screen and (min-width: 1400px) {
    padding: 0 6.5rem;
  }
`

const Headline = styled.span`
  display: block;
  font-size: 3.5rem;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
`

const ContentText = styled.div`
  // padding: 0 6rem;
`

const ExpandedContentWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1100;
  height: 100%;
  width: 100vw;
  padding: 60px 120px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: rgba(0, 0, 0, 0.95);
  opacity: ${props => (props.showModal ? 1 : 0)};
  transition: all 0.5s ${cubicBezier};

  pointer-events: ${props => (props.showModal ? 'auto' : 'none')};
  -webkit-backface-visibility: hidden;
  user-select: none;
`

const ExpandedContentText = styled.div`
  padding-bottom: 3rem;
  max-width: 52rem;
  font-size: 1.3rem;
  margin: 0 auto;
`

const ExpandedTextTrigger = styled.button`
  width: 60px;
  height: 60px;
  background: transparent;
  border-radius: 100%;
  border: 1px solid #fff;
  box-shadow: 0 0 1px 0px #fff inset, 0 0 1px 0px #fff;
  line-height: 60px;
  cursor: pointer;
  transition: all 0.5s ${cubicBezier};
  user-select: none;
  position: relative;
  transform: ${props => (props.showModal ? 'rotate(315deg)' : 'rotate(0deg)')};

  &:before,
  &:after {
    content: '';
    background: #fff;
    height: 1px;
    width: 24px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -12px;
  }

  &:after {
    transform: rotate(90deg);
  }

  @media screen and (${MQ.lg}) {
    &:hover {
      transform: ${props => (props.showModal ? 'rotate(315deg)' : 'rotate(25deg)')};
    }
  }

  &:focus {
    outline: none;
  }
`

const ExpandedContentCloseButton = styled.div`
  margin: 0 auto;
  width: 60px;
  height: 60px;
  min-width: 60px;
  min-height: 60px;
  background: transparent;
  border-radius: 100%;
  border: 1px solid #fff;
  box-shadow: 0 0 1px 0px #fff inset, 0 0 1px 0px #fff;
  line-height: 60px;
  text-align: center;
  user-select: none;
  cursor: pointer;
  position: relative;
  transition: all 0.5s ${cubicBezier}, transform 0.5s ${cubicBezier}, opacity 0.5s ${cubicBezier};
  transform: scale(1);

  &:before,
  &:after {
    content: '';
    background: #fff;
    height: 1px;
    width: 24px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -12px;
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(135deg);
  }

  @media screen and (${MQ.lg}) {
    &:hover {
      transform: scale(1.2);
      opacity: 1;
    }
  }

  &:focus {
    outline: none;
  }
`

export const sectionsContent = graphql`
  fragment SectionContent on WordPressAcf_content {
    ... on WordPressAcf_content {
      primary
      section_title
      id
      headline
      text
      expanded_text
      type
      images {
        source_url
        localFile {
          childImageSharp {
            fixed(width: 1400) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      images_centered_or_full_height
    }
  }
`
