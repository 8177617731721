import React, { memo } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import ProductFullscreenImages from './ProductFullscreenImages'
import { Video } from '@components';

const ProductFullscreen = memo(props => {
  const { video, video_path, images, text, id, isActive } = props
  const hasVideo = !!video_path || !!video;
  
  return (
    <ProductFullscreenWrapper>
      {hasVideo ? (
        <VideoBackground>
          <Video 
            autoPlay
            muted
            loop
            // preload="auto"
            path={video_path}
            inView
          />
        </VideoBackground>
      ) : (
        <ProductFullscreenImages images={images} id={id} />
      )}
      {text && (
        <Content>
          <ContentText dangerouslySetInnerHTML={{ __html: text }} />
        </Content>
      )}
    </ProductFullscreenWrapper>
  )
})

export default ProductFullscreen

const VideoBackground = styled.div`
  user-select: none;
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 1;

  > video {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
  }
`

const ProductFullscreenWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

const Content = styled.div`
  font-size: 2.6rem;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 2rem;
  z-index: 2;
  bottom: 5rem;
  left: 5rem;
  width: 100%;
  max-width: 60rem;

  p {
    line-height: 1.4;
    margin: 0;
  }
`

const ContentText = styled.div``

export const sectionsFullscreen = graphql`
  fragment SectionFullscreen on WordPressAcf_fullscreen {
    ... on WordPressAcf_fullscreen {
      primary
      section_title
      id
      text
      video_path
      fullscreen_background_images {
        adjustment
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000, srcSetBreakpoints: [1200]) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`